import styled from 'styled-components'
import { colors } from 'src/common/constants'

export const ProductHeader = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr auto;
`
export const PriceLine = styled.h3`
  display: grid;
  /* grid-template-columns: repeat(2, min-content); */
  text-align: right;
  margin-bottom: 0;
`
export const Price = styled.strong`
  color: ${colors.main};
`
export const NormalPrice = styled.small`
  color: ${colors.grey3};
  text-decoration: line-through;
`
export const Discount = styled.small`
  color: ${colors.grey4};
  font-weight: bold;
`
export const Description = styled.ul`
  margin-bottom: 0;
  padding-inline-start: 20px;
`
