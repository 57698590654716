import styled, { css } from 'styled-components'
import { Button, Layout, Radio } from 'antd'

import { colors } from './constants'

// MIXINS
export const ResponsiveGridMixin = ({ columns }) => css`
  display: grid;
  gap: 16px;
  grid-template-columns: ${`repeat(${columns.xs || 1}, 1fr)`};

  @media screen and (min-width: 1200px) {
    grid-template-columns: ${`repeat(${columns.xl || 1}, 1fr)`};
  }
`
export const ContentMixin = ({ hasImg, isProducts }) => css`
  height: 100%;
  line-height: 1;
  text-align: ${hasImg ? 'left' : 'center'};
  display: grid;
  gap: 12px;
  grid-template-columns: ${hasImg ? 'auto 1fr' : 'auto'};
  align-items: ${isProducts ? 'start' : 'center'};
  & p {
    margin-bottom: 0.5em;
  }
`

// COMPONENTS
export const WhiteLayout = styled(Layout)`
  background: ${colors.white};
`
export const PaddedContent = styled(Layout.Content)`
  padding: 16px;
  min-height: calc(100vh - 112px);
  display: grid;
  gap: 16px;
  grid-template-rows: repeat(auto-fit, minmax(0, min-content));

  @media screen and (min-width: 1200px) {
    width: 1200px;
    margin: 0 auto;
  }
`
export const UppercaseButton = styled(Button)`
  text-transform: uppercase;
`
export const StepTitle = styled.h2`
  font-weight: bold;
  color: ${colors.main};

  @media screen and (min-width: 1200px) {
    text-align: center;
  }
`
export const ResponsiveGrid = styled.div`
  ${props => ResponsiveGridMixin({ columns: props.columns })}
`
export const RadioGrid = styled(Radio.Group)`
  ${props => ResponsiveGridMixin({ columns: props.columns })}
`
export const RadioButton = styled(Radio.Button)`
  color: ${colors.grey4};
  height: auto;
  padding: ${props => (props.padding === 'small' ? '8px 16px' : '16px')};
  text-align: center;
  border-radius: 2px !important;
  border-left: 1px solid ${colors.grey2};
  margin-top: ${props => (props.hasDiffPrice ? '30px' : 0)};
  &:not(:first-child)::before {
    display: none;
  }
  &.ant-radio-button-wrapper-checked img[height='30'] {
    filter: brightness(3);
  }
`
export const RadioButtonContent = styled.span`
  ${props =>
    ContentMixin({ hasImg: props.hasImg, isProducts: props.isProducts })}
`
