import { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import { getStores } from 'src/redux-store/actions/app-actions'
import { updateStoreId } from 'src/redux-store/actions/user-actions'

import Auth from '../Auth'
import Home from '../Home'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import FeedbackButton from '../../components/FeedbackButton'
import { WhiteLayout, PaddedContent } from '../../common/styled'
import StoreModal from 'src/components/StoreModal'

class App extends Component {
  static propTypes = {
    getStores: PropTypes.func,
    stores: PropTypes.array,
    updateStoreId: PropTypes.func,
    user: PropTypes.object
  }

  state = { isStoreModalVisible: false }

  componentDidMount() {
    const { user } = this.props

    this.tangram = new window.Tangram({
      productId: '463ac5f3-913d-4886-a5da-7755e91c574f', // PUT YOUR PRODUCT ID HERE.
      userLdap: user.LDAP,
      buttonOptions: {
        // This options is needed so you don't have two buttons.
        noShowOnStartup: true
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const { user, getStores } = this.props
    if (isEmpty(prevProps.user) && user.isAdmin) {
      getStores()
      this.openStoreModal()
    }
  }

  updateStoreId = storeId => {
    const { updateStoreId } = this.props
    updateStoreId(storeId)
    this.closeStoreModal()
  }

  openStoreModal = () => this.setState({ isStoreModalVisible: true })
  closeStoreModal = () => this.setState({ isStoreModalVisible: false })

  render() {
    const { isStoreModalVisible } = this.state
    const { user, stores } = this.props
    return (
      <WhiteLayout>
        <Header user={user} changeStore={this.openStoreModal} />
        <Auth>
          <PaddedContent>
            <Switch>
              <Route component={Home} exact path={'/'} />
            </Switch>
          </PaddedContent>
        </Auth>
        <Footer />
        <StoreModal
          stores={stores}
          isVisible={isStoreModalVisible}
          onCloseModal={this.closeStoreModal}
          onSelectStore={this.updateStoreId}
        />
        <FeedbackButton onClick={() => this.tangram.open()} />
      </WhiteLayout>
    )
  }
}
const mapStateToProps = state => ({
  user: state.user.current,
  stores: state.app.stores
})
const mapDispatchToProps = {
  getStores,
  updateStoreId
}
export default connect(mapStateToProps, mapDispatchToProps)(App)
