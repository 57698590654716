import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Avatar, Button, Modal } from 'antd'
import { PictureOutlined } from '@ant-design/icons'
import {
  ProductHeader,
  Discount,
  NormalPrice,
  Price,
  PriceLine,
  Description
} from './styled'

const ProductContent = props => {
  const { intl } = props
  const {
    id,
    text,
    imgUrl,
    descriptions,
    unit,
    price,
    normalPrice,
    discount
  } = props.item
  const [isModalVisible, setIsModalVisible] = useState(false)

  const priceUnit = intl.formatMessage({ id: `product.priceUnit.${unit}` })
  const visibleDescriptions = descriptions?.slice(0, 2)
  const hiddenDescriptions = descriptions?.slice(2, descriptions.length)

  const openModal = useCallback(() => setIsModalVisible(true), [])
  const closeModal = useCallback(() => setIsModalVisible(false), [])

  return (
    <>
      {imgUrl ? (
        <img src={imgUrl} alt={id} />
      ) : (
        <Avatar icon={<PictureOutlined />} size={80} shape={'square'} />
      )}
      <div>
        <ProductHeader>
          <div>
            <h4>{text}</h4>
            <p>
              <em>{`ref. ${id}`}</em>
            </p>
          </div>
          <PriceLine>
            {discount ? (
              <>
                <NormalPrice>{`${normalPrice} ${priceUnit}`}</NormalPrice>
                <Discount>{`-${discount}%`}</Discount>
              </>
            ) : null}
            {price ? <Price>{`${price} ${priceUnit}`}</Price> : null}
          </PriceLine>
        </ProductHeader>
        {descriptions ? (
          <>
            <Description>
              {visibleDescriptions.map((desc, i) => (
                <li key={i} dangerouslySetInnerHTML={{ __html: desc }} />
              ))}
            </Description>
            {hiddenDescriptions.length > 0 ? (
              <>
                <Button type={'link'} onClick={openModal}>
                  <FormattedMessage id={'product.button.moreInfo'} />
                </Button>
                <Modal
                  title={intl.formatMessage({ id: 'product.modal.title' })}
                  visible={isModalVisible}
                  onCancel={closeModal}
                  footer={false}>
                  <Description>
                    {hiddenDescriptions.map((desc, i) => (
                      <li key={i} dangerouslySetInnerHTML={{ __html: desc }} />
                    ))}
                  </Description>
                </Modal>
              </>
            ) : null}
          </>
        ) : null}
      </div>
    </>
  )
}
ProductContent.propTypes = {
  item: PropTypes.object.isRequired
}
export default injectIntl(ProductContent)
