import PropTypes from 'prop-types'
import { has } from 'ramda'
import { RadioButton, RadioButtonContent, RadioGrid } from 'src/common/styled'

const RadioQuestion = props => {
  const {
    items,
    columns,
    buttonStyle,
    padding,
    imgHeight,
    defaultValue,
    onChange
  } = props

  return (
    <RadioGrid
      columns={columns}
      buttonStyle={buttonStyle || 'outline'}
      defaultValue={defaultValue}
      onChange={e => onChange(e.target.value)}>
      {items.map(item => {
        const { id, name, text, imgUrl, description, active } = item
        const TextTag = description ? 'h4' : 'p'
        return (
          <RadioButton
            key={id}
            value={id}
            disabled={has('active', item) ? !active : false}
            padding={padding}>
            <RadioButtonContent hasImg={imgUrl}>
              <>
                {imgUrl ? (
                  <img src={imgUrl} alt={id} height={imgHeight} />
                ) : null}
                <div>
                  <TextTag>{name || text}</TextTag>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: description?.replace(/(?:\r\n|\r|\n)/g, '<br/>')
                    }}
                  />
                </div>
              </>
            </RadioButtonContent>
          </RadioButton>
        )
      })}
    </RadioGrid>
  )
}
RadioQuestion.propTypes = {
  buttonStyle: PropTypes.string,
  columns: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imgHeight: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func,
  padding: PropTypes.string
}
export default RadioQuestion
