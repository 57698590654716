import PropTypes from 'prop-types'
import { List as AntList } from 'antd'

const List = props => {
  const { items } = props
  return (
    <AntList>
      {items.map(item => (
        <AntList.Item key={item.id}>
          <AntList.Item.Meta
            avatar={
              item.imgUrl ? <img src={item.imgUrl} alt={item.id} /> : null
            }
            title={item.text}
            description={item.description}
          />
        </AntList.Item>
      ))}
    </AntList>
  )
}
List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired
}
export default List
