import { Fragment, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Button, List, Modal } from 'antd'

import { DiffPrice, QuartileHeader } from './styled'
import { RadioButton, RadioButtonContent, RadioGrid } from 'src/common/styled'
import ProductContent from '../ProductContent'

const QuartileFeaturesModal = injectIntl(props => {
  const { intl, isVisible, quartile, features, onClose } = props

  return (
    <Modal
      title={intl.formatMessage({ id: 'quartile.modal.title' }, { quartile })}
      visible={isVisible}
      bodyStyle={{ padding: '8px 24px' }}
      onCancel={onClose}
      footer={false}>
      <List
        dataSource={features}
        renderItem={item => <List.Item>{item.text}</List.Item>}
      />
    </Modal>
  )
})

const ProductQuestion = props => {
  const { intl, items, quartileFeatures, columns, value, onChange } = props
  const [quartileModalKey, setQuartileModalKey] = useState(0)

  const openQuartileModal = useCallback(key => setQuartileModalKey(key), [])
  const closeQuartileModal = useCallback(() => setQuartileModalKey(0), [])

  const itemsMapByQuartile = {}
  items.map(item => {
    itemsMapByQuartile[item.quartile] = itemsMapByQuartile[item.quartile]
      ? [...itemsMapByQuartile[item.quartile]]
      : []
    itemsMapByQuartile[item.quartile].push(item)
    return item
  })

  return Object.keys(itemsMapByQuartile).map(key => (
    <Fragment key={key}>
      <QuartileHeader>
        <h3>
          <FormattedMessage id={`quartile.label.${key}`} />
        </h3>
        <Button type={'text'} onClick={() => openQuartileModal(key)}>
          <FormattedMessage id={`quartile.button.showFeatures`} />
        </Button>
      </QuartileHeader>
      <QuartileFeaturesModal
        isVisible={quartileModalKey === key}
        quartile={intl.formatMessage({ id: `quartile.label.${key}` })}
        features={quartileFeatures[`q${key}Features`]}
        onClose={closeQuartileModal}
      />
      <RadioGrid
        columns={columns}
        buttonStyle={'outline'}
        value={value}
        onChange={e => onChange(e.target.value)}>
        {itemsMapByQuartile[key].map(item => {
          const { id, diffPrice, unit } = item
          const priceUnit = intl.formatMessage({ id: `product.priceUnit.${unit}` })
          return (
            <RadioButton key={id} value={id} hasDiffPrice={diffPrice}>
              {diffPrice ? (
                <DiffPrice>{`+${diffPrice} ${priceUnit}`}</DiffPrice>
              ) : null}
              <RadioButtonContent hasImg isProducts>
                <ProductContent item={item} />
              </RadioButtonContent>
            </RadioButton>
          )
        })}
      </RadioGrid>
    </Fragment>
  ))
}
ProductQuestion.propTypes = {
  columns: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func
}
export default injectIntl(ProductQuestion)
