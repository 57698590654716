import PropTypes from 'prop-types'
import { Card } from 'antd'
import { CardContent, Grid } from './styled'
import ProductContent from '../ProductContent'

const CardGrid = props => {
  const { items, columns, isProducts } = props

  return (
    <Grid columns={columns}>
      {items.map(item => {
        return (
          <Card key={item.id} bodyStyle={{ height: '100%', padding: 16 }}>
            <CardContent isProducts={isProducts}>
              {isProducts ? <ProductContent item={item} /> : ''}
            </CardContent>
          </Card>
        )
      })}
    </Grid>
  )
}
CardGrid.propTypes = {
  columns: PropTypes.object,
  isProducts: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object).isRequired
}
export default CardGrid
