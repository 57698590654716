import { isEmpty } from 'ramda'
import {
  ADD_ANSWER,
  ADD_QUESTION,
  GET_ADVICE,
  GET_BENEFITS,
  GET_DEPARTMENTS,
  GET_PRODUCTS,
  GET_NEXT_QUESTION,
  GET_PREV_QUESTION,
  GET_RELATED_PRODUCTS,
  GET_SALES_PLANS,
  GET_STORES,
  REMOVE_ANSWER,
  REMOVE_QUESTION,
  RESET_CURRENT_ANSWER,
  RESET_CURRENT_QUESTION,
  RESET_RELATED_PRODUCTS,
  RESET_STEPS,
  UPDATE_CURRENT_ANSWER,
  UPDATE_CURRENT_DEPARTMENT,
  UPDATE_CURRENT_PRODUCT,
  UPDATE_CURRENT_SALES_PLAN,
  GET_QUARTILE_FEATURES,
  RESET_CURRENT_PRODUCT
} from '../action-types'

const initialState = {
  currentDepartment: undefined,
  currentSalesPlan: undefined,
  currentAnswer: undefined,
  currentProduct: undefined,
  answers: [],
  stores: [],
  departments: [],
  salesPlans: [],
  advice: [],
  currentQuestion: {},
  questions: [],
  products: [],
  quartileFeatures: {},
  benefits: [],
  relatedProducts: []
}
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case RESET_STEPS:
      return initialState
    case UPDATE_CURRENT_DEPARTMENT:
      return { ...state, currentDepartment: payload }
    case UPDATE_CURRENT_SALES_PLAN:
      return { ...state, currentSalesPlan: payload }
    case RESET_CURRENT_QUESTION:
      return { ...state, currentQuestion: initialState.currentQuestion }
    case ADD_QUESTION:
      return {
        ...state,
        questions: !isEmpty(state.currentQuestion)
          ? [...state.questions, state.currentQuestion]
          : []
      }
    case REMOVE_QUESTION:
      return { ...state, questions: state.questions.slice(0, -1) }
    case UPDATE_CURRENT_ANSWER:
      return { ...state, currentAnswer: payload }
    case RESET_CURRENT_ANSWER:
      return { ...state, currentAnswer: initialState.currentAnswer }
    case ADD_ANSWER:
      return { ...state, answers: [...state.answers, payload] }
    case REMOVE_ANSWER:
      return { ...state, answers: state.answers.slice(0, -1) }
    case UPDATE_CURRENT_PRODUCT:
      return { ...state, currentProduct: payload }
    case RESET_CURRENT_PRODUCT:
      return { ...state, currentProduct: initialState.currentProduct }
    case RESET_RELATED_PRODUCTS:
      return { ...state, relatedProducts: initialState.relatedProducts }
    case GET_STORES:
      return { ...state, stores: payload }
    case GET_DEPARTMENTS:
      return { ...state, departments: payload }
    case GET_SALES_PLANS:
      return { ...state, salesPlans: payload }
    case GET_ADVICE:
      return { ...state, advice: payload }
    case GET_BENEFITS:
      return { ...state, benefits: payload }
    case GET_NEXT_QUESTION:
    case GET_PREV_QUESTION:
      return { ...state, currentQuestion: payload }
    case GET_PRODUCTS:
      return { ...state, products: payload }
    case GET_QUARTILE_FEATURES:
      return { ...state, quartileFeatures: payload }
    case GET_RELATED_PRODUCTS:
      return { ...state, relatedProducts: payload }
    default:
      return state
  }
}
export default reducer
