import { combineReducers } from 'redux'

import user from './user-reducer'
import app from './app-reducer'
import analytics from './analytics-reducer'

export default combineReducers({
  user,
  app,
  analytics
})