// USER
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN'
export const UPDATE_STORE_ID = 'UPDATE_STORE_ID'

export const RESET_STEPS = 'RESET_STEPS'
export const UPDATE_CURRENT_DEPARTMENT = 'UPDATE_CURRENT_DEPARTMENT'
export const UPDATE_CURRENT_SALES_PLAN = 'UPDATE_CURRENT_SALES_PLAN'
export const RESET_CURRENT_QUESTION = 'RESET_CURRENT_QUESTION'
export const ADD_QUESTION = 'ADD_QUESTION'
export const REMOVE_QUESTION = 'REMOVE_QUESTION'
export const UPDATE_CURRENT_ANSWER = 'UPDATE_CURRENT_ANSWER'
export const RESET_CURRENT_ANSWER = 'RESET_CURRENT_ANSWER'
export const ADD_ANSWER = 'ADD_ANSWER'
export const REMOVE_ANSWER = 'REMOVE_ANSWER'
export const UPDATE_CURRENT_PRODUCT = 'UPDATE_CURRENT_PRODUCT'
export const RESET_CURRENT_PRODUCT = 'RESET_CURRENT_PRODUCT'
export const RESET_RELATED_PRODUCTS = 'RESET_RELATED_PRODUCTS'

export const GET_STORES = 'GET_STORES'
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS'
export const GET_SALES_PLANS = 'GET_SALES_PLANS'
export const GET_ADVICE = 'GET_ADVICE'
export const GET_NEXT_QUESTION = 'GET_NEXT_QUESTION'
export const GET_PREV_QUESTION = 'GET_PREV_QUESTION'
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_QUARTILE_FEATURES = 'GET_QUARTILE_FEATURES'
export const GET_BENEFITS = 'GET_BENEFITS'
export const GET_RELATED_PRODUCTS = 'GET_RELATED_PRODUCTS'

// ANALYTICS
export const UPDATE_NAVIGATION_UUID = 'UPDATE_NAVIGATION_UUID'
export const RESET_NAVIGATION_UUID = 'RESET_NAVIGATION_UUID'
export const CREATE_ANALYTICS = 'CREATE_ANALYTICS'
export const DELETE_ANALYTICS = 'DELETE_ANALYTICS'