import { v4 as uuidv4 } from 'uuid'
import { CREATE_ANALYTICS, DELETE_ANALYTICS, RESET_NAVIGATION_UUID, UPDATE_NAVIGATION_UUID } from '../action-types'
import { handleServerError } from 'src/common/utils'
import api from 'src/common/api'
import { ANALYTICS_ACTION_SHOW_SALES_PLAN } from 'src/common/constants'

export const updateNavigationUuid = () => dispatch => {
  dispatch({ type: UPDATE_NAVIGATION_UUID, payload: uuidv4() })
  dispatch(createAnalytics(ANALYTICS_ACTION_SHOW_SALES_PLAN))
}

export const resetNavigationUuid = () => {
  return { type: RESET_NAVIGATION_UUID }
}

export const createAnalytics = (action, actionData) => async (dispatch, getState) => {
  const { storeId } = getState().user.current
  const { navigationUuid } = getState().analytics
  const salesPlanId = getState().app.currentSalesPlan
  const body = {
    storeId,
    navigationUuid,
    salesPlanId,
    action,
    actionData
  }
  try {
    await api.analytics.create(body)
    return dispatch({ type: CREATE_ANALYTICS, payload: action })
  } catch (error) {
    return handleServerError(error)
  }
}

export const deleteAnalytics = (action, actionData) => async (dispatch, getState) => {
  const { storeId } = getState().user.current
  const { navigationUuid } = getState().analytics
  const salesPlanId = getState().app.currentSalesPlan
  const body = {
    storeId,
    navigationUuid,
    salesPlanId,
    action,
    actionData
  }
  try {
    await api.analytics.delete(body)
    return dispatch({ type: DELETE_ANALYTICS, payload: action })
  } catch (error) {
    return handleServerError(error)
  }
}
