import axios from 'axios'
import config from './config'
import { BU, SALES_PLAN_STATUS_PUBLISHED } from './constants'

export const BE_API = axios.create({
  baseURL: config.API_URL,
  headers: {
    'API-Key': '535A44A4-6DAB-41B6-903E-438DA81AE793'
  }
})

const apis = {
  user: {
    authenticate: token =>
      BE_API.get('/user', { headers: { Authorization: `Bearer ${token}` } })
  },
  store: {
    list: () => BE_API.get('/stores', { params: { bu: BU } })
  },
  department: {
    list: () =>
      BE_API.get('/departments', { params: { bu: BU, hasSalesPlans: true } })
  },
  salesPlan: {
    list: departmentId =>
      BE_API.get('/sales-plans', {
        params: { bu: BU, departmentId, status: SALES_PLAN_STATUS_PUBLISHED }
      }),
    advice: (salesPlanId, type) =>
      BE_API.get(`/sales-plans/${salesPlanId}/advice`, {
        params: { bu: BU, type }
      }),
    benefits: salesPlanId =>
      BE_API.get(`/sales-plans/${salesPlanId}/benefits`, { params: { bu: BU } })
  },
  question: {
    get: (salesPlanId, relatedQuestionId, answerIds) =>
      BE_API.get('/questions', {
        params: { bu: BU, salesPlanId, relatedQuestionId, answerIds }
      })
  },
  product: {
    list: (answerIds, storeId, salesPlanId) =>
      BE_API.get('/products', {
        params: { bu: BU, storeId, salesPlanId, answerIds }
      }),
    relatedProducts: (productId, type, storeId) =>
      BE_API.get(`/products/${productId}/related-products`, {
        params: { bu: BU, storeId, type }
      })
  },
  quartile: {
    features: salesPlanId =>
      BE_API.get(`/sales-plans/${salesPlanId}/quartile-features`, {
        params: { bu: BU }
      })
  },
  analytics: {
    create: body => BE_API.post('/analytics', body, { params: { bu: BU } }),
    delete: body => BE_API.delete('/analytics', { data: body, params: { bu: BU } })
  }
}
export default apis
