import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'

// ROUTING
import { BrowserRouter } from 'react-router-dom'
import App from './containers/App'

// REDUX
import { Provider } from 'react-redux'
import store from './redux-store/store'

// TRANSLATIONS
import { IntlProvider } from 'react-intl'
import { locale, messages } from './common/translations'

import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={'/'}>
        <IntlProvider locale={locale} messages={messages}>
          <App />
        </IntlProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
