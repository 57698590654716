import { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import { validateToken } from '../../redux-store/actions/user-actions'

import Loader from '../../components/Loader'

import config from '../../common/config'
import { getCookie, setCookie } from '../../common/cookies'

class Auth extends Component {
  static propTypes = {
    user: PropTypes.object,
    validateToken: PropTypes.func,
  }

  async componentDidMount () {
    const { user, location, history, validateToken } = this.props
    const queryParams = new URLSearchParams(location.search)
    const tokenParam = queryParams.get('token')
    const authCookie = getCookie('auth_token')

    if (!authCookie && !tokenParam) {
      window.location.href = config.LOGIN_URL
    } else {
      const token = tokenParam || authCookie || user.token
      if (!authCookie || authCookie !== tokenParam)
        setCookie('auth_token', token)

      await validateToken(token)
      queryParams.delete('token')
      history.replace(`${location.pathname}?${queryParams.toString()}`)

    }

  }

  render () {
    const { user, children } = this.props
    return isEmpty(user) ? <Loader /> : children
  }
}
const mapStateToProps = state => ({
  user: state.user.current
})
const mapDispatchToProps = {
  validateToken
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Auth))