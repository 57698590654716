import {
  ADD_ANSWER,
  ADD_QUESTION,
  GET_ADVICE,
  GET_BENEFITS,
  GET_DEPARTMENTS,
  GET_PRODUCTS,
  GET_NEXT_QUESTION,
  GET_PREV_QUESTION,
  GET_RELATED_PRODUCTS,
  GET_SALES_PLANS,
  GET_STORES,
  REMOVE_ANSWER,
  REMOVE_QUESTION,
  RESET_CURRENT_ANSWER,
  RESET_CURRENT_QUESTION,
  RESET_RELATED_PRODUCTS,
  RESET_STEPS,
  UPDATE_CURRENT_ANSWER,
  UPDATE_CURRENT_DEPARTMENT,
  UPDATE_CURRENT_PRODUCT,
  UPDATE_CURRENT_SALES_PLAN,
  GET_QUARTILE_FEATURES,
  RESET_CURRENT_PRODUCT
} from '../action-types'
import api from 'src/common/api'
import { handleServerError } from 'src/common/utils'

export const resetSteps = () => {
  return { type: RESET_STEPS }
}
export const updateCurrentDepartment = department => {
  return { type: UPDATE_CURRENT_DEPARTMENT, payload: department }
}
export const updateCurrentSalesPlan = salesPlan => {
  return { type: UPDATE_CURRENT_SALES_PLAN, payload: salesPlan }
}
export const resetCurrentQuestion = () => {
  return { type: RESET_CURRENT_QUESTION }
}
export const addQuestion = () => {
  return { type: ADD_QUESTION }
}
export const removeQuestion = () => {
  return { type: REMOVE_QUESTION }
}
export const updateCurrentAnswer = answer => {
  return { type: UPDATE_CURRENT_ANSWER, payload: answer }
}
export const resetCurrentAnswer = () => {
  return { type: RESET_CURRENT_ANSWER }
}
export const addAnswer = answer => {
  return { type: ADD_ANSWER, payload: answer }
}
export const removeAnswer = () => {
  return { type: REMOVE_ANSWER }
}
export const updateCurrentProduct = product => {
  return { type: UPDATE_CURRENT_PRODUCT, payload: product }
}
export const resetCurrentProduct = () => {
  return { type: RESET_CURRENT_PRODUCT }
}
export const resetRelatedProducts = () => {
  return { type: RESET_RELATED_PRODUCTS }
}

export const getStores = () => async dispatch => {
  try {
    const res = await api.store.list()
    return dispatch({ type: GET_STORES, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getDepartments = () => async dispatch => {
  try {
    const res = await api.department.list()
    return dispatch({ type: GET_DEPARTMENTS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getSalesPlans = () => async (dispatch, getState) => {
  const departmentId = getState().app.currentDepartment
  try {
    const res = await api.salesPlan.list(departmentId)
    return dispatch({ type: GET_SALES_PLANS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getAdvice = type => async (dispatch, getState) => {
  const salesPlanId = getState().app.currentSalesPlan
  try {
    const res = await api.salesPlan.advice(salesPlanId, type)
    return dispatch({ type: GET_ADVICE, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getBenefits = () => async (dispatch, getState) => {
  const salesPlanId = getState().app.currentSalesPlan
  try {
    const res = await api.salesPlan.benefits(salesPlanId)
    return dispatch({ type: GET_BENEFITS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getNextQuestion = () => async (dispatch, getState) => {
  const salesPlanId = getState().app.currentSalesPlan
  const parentQuestionId = getState().app.currentQuestion?.id
  const answerIds = getState().app.answers.join() || undefined
  try {
    const res = await api.question.get(salesPlanId, parentQuestionId, answerIds)
    return dispatch({ type: GET_NEXT_QUESTION, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}
export const getPrevQuestion = () => async (dispatch, getState) => {
  const { currentQuestion, questions, answers } = getState().app
  const prevQuestion =
    questions.find(q => q.id === currentQuestion.parentId) ||
    questions[questions.length - 1]
  const prevAnswer = answers[answers.length - 1]

  dispatch(updateCurrentAnswer(prevAnswer))
  dispatch(removeQuestion())
  dispatch(removeAnswer())
  return dispatch({ type: GET_PREV_QUESTION, payload: prevQuestion })
}

export const getProducts = () => async (dispatch, getState) => {
  const { storeId } = getState().user.current
  const salesPlanId = getState().app.currentSalesPlan
  const answerIds = getState().app.answers.join()
  try {
    const res = await api.product.list(answerIds, storeId, salesPlanId)
    return dispatch({ type: GET_PRODUCTS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getQuartileFeatures = () => async (dispatch, getState) => {
  const salesPlanId = getState().app.currentSalesPlan
  try {
    const res = await api.quartile.features(salesPlanId)
    return dispatch({ type: GET_QUARTILE_FEATURES, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getRelatedProducts = type => async (dispatch, getState) => {
  const { storeId } = getState().user.current
  const productId = getState().app.currentProduct
  try {
    const res = await api.product.relatedProducts(productId, type, storeId)
    return dispatch({ type: GET_RELATED_PRODUCTS, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}
