import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { UppercaseButton } from 'src/common/styled'
import { ButtonsRowContainer } from './styled'

const buttonsConfig = {
  next: {
    id: 'home.button.next',
    type: 'primary'
  },
  viewSalesPlan: {
    id: 'home.button.viewSalesPlan',
    type: 'primary'
  },
  closeSalesPlan: {
    id: 'home.button.closeSalesPlan',
    type: 'primary'
  },
  back: {
    id: 'home.button.back',
    type: 'link'
  },
  cancel: {
    id: 'home.button.cancel',
    type: 'link'
  }
}

const ButtonsRow = props => {
  const { items } = props
  return (
    <ButtonsRowContainer>
      {items.map(item => {
        const { key, disabled, onClick } = item
        return key !== 'empty' ? (
          <UppercaseButton
            key={key}
            type={buttonsConfig[key].type}
            disabled={disabled}
            onClick={onClick}>
            <FormattedMessage id={buttonsConfig[key].id} />
          </UppercaseButton>
        ) : (
          <br key={key} />
        )
      })}
    </ButtonsRowContainer>
  )
}
ButtonsRow.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired
}
export default ButtonsRow
