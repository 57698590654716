import { RESET_NAVIGATION_UUID, UPDATE_NAVIGATION_UUID } from "../action-types"

const initialState = {}
const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_NAVIGATION_UUID:
      return { ...state, navigationUuid: payload }
    case RESET_NAVIGATION_UUID:
      return { ...state, navigationUuid: undefined }
    default:
      return state
  }
}
export default reducer
