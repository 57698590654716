import styled from 'styled-components'

export const ButtonsRowContainer = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(2, min-content);
    justify-content: space-between;
  }
`
