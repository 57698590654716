export const BU = 5

export const SALES_PLAN_STATUS_PUBLISHED = 'PUBLISHED'

export const ADVICE_TYPE_PRE_SALE = 'pre-sale'
export const ADVICE_TYPE_POST_SALE = 'post-sale'

export const ANALYTICS_ACTION_SHOW_SALES_PLAN = 'SHOW_SALES_PLAN'
export const ANALYTICS_ACTION_ANSWER = 'ANSWER'
export const ANALYTICS_ACTION_PRODUCT_CHOICE = 'PRODUCT_CHOICE'
export const ANALYTICS_ACTION_SHOW_RELATED_PRODUCTS = 'SHOW_RELATED_PRODUCTS'
export const ANALYTICS_ACTION_CLOSE_SALES_PLAN = 'CLOSE_SALES_PLAN'