import styled from 'styled-components'
import { ResponsiveGridMixin, ContentMixin } from 'src/common/styled'

export const Grid = styled.div`
  ${props => ResponsiveGridMixin({ columns: props.columns })}
`

export const CardContent = styled.span`
  ${props => ContentMixin({ hasImg: true, isProducts: props.isProducts })}
`
