import styled from 'styled-components'
import { colors } from 'src/common/constants'

export const DiffPrice = styled.span`
  line-height: 1;
  padding: 8px;
  background: ${colors.main};
  color: ${colors.white};
  position: absolute;
  top: -30px;
  right: 0;
  border-radius: 2px 2px 0 0;
`

export const QuartileHeader = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr auto;
`