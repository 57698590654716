import { Component } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { FormattedMessage } from 'react-intl'
import { Card, Result, Steps } from 'antd'
import { connect } from 'react-redux'

import {
  getDepartments,
  getSalesPlans,
  getAdvice,
  getNextQuestion,
  getPrevQuestion,
  getProducts,
  getQuartileFeatures,
  getBenefits,
  getRelatedProducts,
  updateCurrentDepartment,
  updateCurrentSalesPlan,
  resetCurrentQuestion,
  addQuestion,
  updateCurrentAnswer,
  resetCurrentAnswer,
  addAnswer,
  updateCurrentProduct,
  resetCurrentProduct,
  resetRelatedProducts,
  resetSteps
} from 'src/redux-store/actions/app-actions'

import {
  updateNavigationUuid,
  resetNavigationUuid,
  createAnalytics,
  deleteAnalytics
} from 'src/redux-store/actions/analytics-actions'

import Loader from '../../components/Loader'
import RadioQuestion from '../../components/RadioQuestion'
import ProductQuestion from 'src/components/ProductQuestion'
import ProductContent from 'src/components/ProductContent'
import CardGrid from 'src/components/CardGrid'
import ButtonsRow from '../../components/ButtonsRow'
import List from '../../components/List'
import {
  RadioButtonContent,
  ResponsiveGrid,
  StepTitle
} from '../../common/styled'

import {
  ADVICE_TYPE_POST_SALE,
  ADVICE_TYPE_PRE_SALE,
  ANALYTICS_ACTION_ANSWER,
  ANALYTICS_ACTION_CLOSE_SALES_PLAN,
  ANALYTICS_ACTION_PRODUCT_CHOICE,
  ANALYTICS_ACTION_SHOW_RELATED_PRODUCTS
} from 'src/common/constants'

class Home extends Component {
  static propTypes = {
    addAnswer: PropTypes.func,
    addQuestion: PropTypes.func,
    answers: PropTypes.array,
    currentDepartment: PropTypes.string,
    currentSalesPlan: PropTypes.number,
    departments: PropTypes.arrayOf(PropTypes.object),
    getBenefits: PropTypes.func,
    getDepartments: PropTypes.func,
    getNextQuestion: PropTypes.func,
    getPrevQuestion: PropTypes.func,
    getProducts: PropTypes.func,
    getQuartileFeatures: PropTypes.func,
    getRelatedProducts: PropTypes.func,
    getSalesPlans: PropTypes.func,
    quartileFeatures: PropTypes.object,
    questions: PropTypes.arrayOf(PropTypes.object),
    salesPlans: PropTypes.arrayOf(PropTypes.object),
    resetCurrentAnswer: PropTypes.func,
    resetCurrentQuestion: PropTypes.func,
    resetRelatedProducts: PropTypes.func,
    resetSteps: PropTypes.func,
    updateCurrentAnswer: PropTypes.func,
    updateCurrentDepartment: PropTypes.func,
    updateCurrentSalesPlan: PropTypes.func,
    updateCurrentProduct: PropTypes.func
  }

  state = {
    isLoading: false,
    isLoadingProducts: false,
    currentStep: 'department'
  }

  componentDidMount () {
    this.getDepartments()
  }

  getDepartments = async () => {
    const { getDepartments } = this.props
    this.setState({ isLoading: true })
    await getDepartments()
    this.setState({ isLoading: false })
  }

  getSalesPlans = async () => {
    const { getSalesPlans } = this.props
    this.setState({ isLoading: true })
    await getSalesPlans()
    this.setState({ isLoading: false })
  }

  getAdvice = async type => {
    const { getAdvice } = this.props
    this.setState({ isLoading: true })
    await getAdvice(type)
    this.setState({ isLoading: false })
  }

  getNextQuestion = async () => {
    const { getNextQuestion, resetCurrentAnswer } = this.props
    this.setState({ isLoading: true })
    resetCurrentAnswer()
    await getNextQuestion()
    this.setState({ isLoading: false })
  }
  getPrevQuestion = async () => {
    const { getPrevQuestion } = this.props
    this.setState({ isLoading: true })
    await getPrevQuestion()
    this.setState({ isLoading: false })
  }

  getProducts = async () => {
    const { getProducts, getQuartileFeatures } = this.props
    this.setState({ isLoading: true })
    await getProducts()
    await getQuartileFeatures()
    this.setState({ isLoading: false })
  }

  getBenefits = async () => {
    const { getBenefits, resetRelatedProducts } = this.props
    this.setState({ isLoading: true, relatedProductsType: undefined })
    resetRelatedProducts()
    await getBenefits()
    this.setState({ isLoading: false })
  }

  getRelatedProducts = async type => {
    const { getRelatedProducts, createAnalytics } = this.props
    this.setState({ isLoadingProducts: true, relatedProductsType: type })

    await createAnalytics(ANALYTICS_ACTION_SHOW_RELATED_PRODUCTS, type)
    await getRelatedProducts(type)
    this.setState({ isLoadingProducts: false })
  }

  resetSteps = async () => {
    const { resetSteps, resetNavigationUuid, createAnalytics } = this.props
    await createAnalytics(ANALYTICS_ACTION_CLOSE_SALES_PLAN)
    resetNavigationUuid()

    this.setState({ currentStep: 'department', relatedProductsType: undefined })
    resetSteps()
    this.getDepartments()
  }

  goToStepSalesPlan = async () => {
    this.setState({ currentStep: 'salesPlan' })
    this.getSalesPlans()
  }

  goToStepPreSaleAdvice = async () => {
    const {
      currentQuestion,
      answers,
      navigationUuid,
      resetCurrentQuestion,
      resetCurrentAnswer,
      updateNavigationUuid,
      deleteAnalytics
    } = this.props

    if (currentQuestion.parentId) {
      await deleteAnalytics(ANALYTICS_ACTION_ANSWER, answers[answers.length - 1])
      return this.getPrevQuestion()
    }

    if (!navigationUuid) updateNavigationUuid()
    resetCurrentQuestion()
    resetCurrentAnswer()
    this.setState({ currentStep: 'preSaleAdvice' })
    this.getAdvice(ADVICE_TYPE_PRE_SALE)
  }

  goToStepQuestion = async direction => {
    const { answers, resetCurrentProduct, deleteAnalytics } = this.props

    this.setState({ currentStep: 'question' })
    if (direction === 'next') this.getNextQuestion()
    if (direction === 'prev') {
      await deleteAnalytics(ANALYTICS_ACTION_ANSWER, answers[answers.length - 1])
      resetCurrentProduct()
      this.getPrevQuestion()
    }
  }

  goToStepProducts = async () => {
    const {
      currentQuestion,
      currentAnswer,
      currentProduct,
      addQuestion,
      addAnswer,
      resetCurrentQuestion,
      resetCurrentAnswer,
      createAnalytics,
      deleteAnalytics
    } = this.props

    if (!isEmpty(currentQuestion)) addQuestion(currentQuestion)
    if (currentAnswer) {
      addAnswer(currentAnswer)
      await createAnalytics(ANALYTICS_ACTION_ANSWER, currentAnswer)
    }
    if (currentQuestion.childId) return this.getNextQuestion()

    if (currentProduct)
      await deleteAnalytics(ANALYTICS_ACTION_PRODUCT_CHOICE, currentProduct)
    resetCurrentQuestion()
    resetCurrentAnswer()
    this.setState({ currentStep: 'products' })
    this.getProducts()
  }

  goToStepSummary = async () => {
    const { currentProduct, createAnalytics } = this.props
    await createAnalytics(ANALYTICS_ACTION_PRODUCT_CHOICE, currentProduct)

    this.setState({ currentStep: 'summary' })
  }

  goToStepBenefits = () => {
    this.setState({ currentStep: 'benefits' })
    this.getBenefits()
  }

  goToStepPostSaleAdvice = () => {
    this.setState({ currentStep: 'postSaleAdvice' })
    this.getAdvice(ADVICE_TYPE_POST_SALE)
  }

  goToStepRelatedProducts = () => {
    this.setState({ currentStep: 'related-products' })
  }

  renderDepartments () {
    const { currentDepartment, departments, updateCurrentDepartment } =
      this.props
    return departments.length > 0 ? (
      <>
        <StepTitle>
          <FormattedMessage id={'home.title.chooseDepartment'} />
        </StepTitle>
        <RadioQuestion
          items={departments}
          columns={{ xs: 2, xl: 3 }}
          buttonStyle={'solid'}
          onChange={updateCurrentDepartment}
          imgHeight={30}
        />
        <ButtonsRow
          items={[
            { key: 'empty' },
            {
              key: 'next',
              disabled: !currentDepartment,
              onClick: this.goToStepSalesPlan
            }
          ]}
        />
      </>
    ) : (
      <Result
        status={404}
        title={<FormattedMessage id={'home.text.noSalesPlans'} />}
      />
    )
  }

  renderSalesPlans () {
    const { currentSalesPlan, salesPlans, updateCurrentSalesPlan } = this.props
    return (
      <>
        <StepTitle>
          <FormattedMessage id={'home.title.chooseSalesPlan'} />
        </StepTitle>
        <RadioQuestion
          items={salesPlans}
          columns={{ xs: 2, xl: 3 }}
          buttonStyle={'solid'}
          // padding={'small'}
          defaultValue={currentSalesPlan}
          onChange={updateCurrentSalesPlan}
        />
        <ButtonsRow
          items={[
            { key: 'back', onClick: this.resetSteps },
            {
              key: 'viewSalesPlan',
              disabled: !currentSalesPlan,
              onClick: this.goToStepPreSaleAdvice
            }
          ]}
        />
      </>
    )
  }

  renderPreSaleAdvice () {
    const { advice } = this.props
    const adviceCardStyle = {
      maxWidth: 800,
      width: '-webkit-fill-available',
      margin: '0 auto'
    }
    return (
      <>
        <StepTitle>
          <FormattedMessage id={'home.title.preSaleAdvice'} />
        </StepTitle>
        <Card style={adviceCardStyle}>
          <List items={advice} />
        </Card>
        <ButtonsRow
          items={[
            {
              key: 'back',
              onClick: this.goToStepSalesPlan
            },
            {
              key: 'next',
              onClick: () => this.goToStepQuestion('next')
            }
          ]}
        />
      </>
    )
  }

  renderQuestion () {
    const { currentQuestion, currentAnswer, updateCurrentAnswer } = this.props
    return (
      <>
        <StepTitle>{currentQuestion.text}</StepTitle>
        <RadioQuestion
          items={currentQuestion.answers || []}
          columns={{ xs: 1, xl: 3 }}
          defaultValue={currentAnswer}
          onChange={updateCurrentAnswer}
        />
        <ButtonsRow
          items={[
            {
              key: 'back',
              onClick: this.goToStepPreSaleAdvice
            },
            {
              key: 'next',
              disabled: !currentAnswer,
              onClick: this.goToStepProducts
            }
          ]}
        />
      </>
    )
  }

  renderProducts () {
    const { currentProduct, products, quartileFeatures, updateCurrentProduct } =
      this.props
    return (
      <>
        <StepTitle>
          <FormattedMessage id={'home.title.chooseProduct'} />
        </StepTitle>
        {products.length > 0 ? (
          <ProductQuestion
            items={products}
            quartileFeatures={quartileFeatures}
            columns={{ xs: 1, xl: 2 }}
            value={currentProduct}
            onChange={updateCurrentProduct}
          />
        ) : (
          <Result
            status={404}
            title={<FormattedMessage id={'home.text.noProducts'} />}
          />
        )}
        <ButtonsRow
          items={[
            {
              key: 'back',
              onClick: () => this.goToStepQuestion('prev')
            },
            {
              key: 'next',
              disabled: !currentProduct,
              onClick: this.goToStepSummary
            }
          ]}
        />
      </>
    )
  }

  renderSummary () {
    const { questions, answers, products, currentProduct } = this.props
    const product = products.find(p => p.id === +currentProduct) || {}
    return (
      <>
        <StepTitle>
          <FormattedMessage id={'home.title.summary'} />
        </StepTitle>
        <ResponsiveGrid columns={{ xs: 1, xl: 2 }}>
          <div>
            <h3>
              <FormattedMessage id={'home.text.userFlow'} />
            </h3>
            <Card>
              <Steps current={questions.length} direction={'vertical'}>
                {questions.map((q, i) => {
                  const answer = q.answers.find(a => a.id === +answers[i])
                  return (
                    <Steps.Step
                      key={i}
                      title={q?.text}
                      description={answer?.text}
                      status={'process'}
                    />
                  )
                })}
              </Steps>
            </Card>
          </div>
          <div>
            <h3>
              <FormattedMessage
                id={`home.text.selectedProduct.${product.quartile}`}
              />
            </h3>
            <Card>
              <RadioButtonContent hasImg isProducts>
                <ProductContent item={product} />
              </RadioButtonContent>
            </Card>
          </div>
        </ResponsiveGrid>
        <ButtonsRow
          items={[
            {
              key: 'back',
              onClick: this.goToStepProducts
            },
            {
              key: 'next',
              onClick: this.goToStepBenefits
            }
          ]}
        />
      </>
    )
  }

  renderBenefits () {
    const { benefits } = this.props
    const benefitCardStyle = {
      maxWidth: 800,
      width: '-webkit-fill-available',
      margin: '0 auto'
    }
    return (
      <>
        <StepTitle>
          <FormattedMessage id={'home.title.benefits'} />
        </StepTitle>
        <Card style={benefitCardStyle}>
          <List items={benefits} />
        </Card>
        <ButtonsRow
          items={[
            {
              key: 'back',
              onClick: this.goToStepSummary
            },
            {
              key: 'next',
              onClick: this.goToStepPostSaleAdvice
            }
          ]}
        />
      </>
    )
  }

  renderPostSaleAdvice () {
    const { advice } = this.props
    const adviceCardStyle = {
      maxWidth: 800,
      width: '-webkit-fill-available',
      margin: '0 auto'
    }
    return (
      <>
        <StepTitle>
          <FormattedMessage id={'home.title.postSaleAdvice'} />
        </StepTitle>
        <Card style={adviceCardStyle}>
          <List items={advice} />
        </Card>
        <ButtonsRow
          items={[
            {
              key: 'back',
              onClick: this.goToStepBenefits
            },
            {
              key: 'next',
              onClick: this.goToStepRelatedProducts
            }
          ]}
        />
      </>
    )
  }

  renderRelatedProducts () {
    const { isLoadingProducts, relatedProductsType } = this.state
    const { relatedProducts } = this.props
    const relatedProductsTypes = [
      {
        id: 'pose',
        text: <FormattedMessage id={'home.radio.pose'} />
      },
      {
        id: 'product',
        text: <FormattedMessage id={'home.radio.product'} />
      }
    ]

    return (
      <>
        <StepTitle>
          <FormattedMessage id={'home.title.relatedProducts'} />
        </StepTitle>
        <RadioQuestion
          items={relatedProductsTypes}
          columns={{ xs: 2, xl: 2 }}
          buttonStyle={'solid'}
          padding={'small'}
          onChange={this.getRelatedProducts}
        />
        {isLoadingProducts ? (
          <Loader />
        ) : relatedProducts.length === 0 && relatedProductsType ? (
          <Result
            status={404}
            title={<FormattedMessage id={'home.text.noRelatedProducts'} />}
          />
        ) : (
          <CardGrid
            items={relatedProducts}
            columns={{ xs: 1, xl: 2 }}
            isProducts
          />
        )}
        <ButtonsRow
          items={[
            {
              key: 'back',
              onClick: this.goToStepPostSaleAdvice
            },
            {
              key: 'closeSalesPlan',
              onClick: this.resetSteps
            }
          ]}
        />
      </>
    )
  }

  renderSteps () {
    const { currentStep } = this.state
    switch (currentStep) {
      case 'department':
        return this.renderDepartments()
      case 'salesPlan':
        return this.renderSalesPlans()
      case 'preSaleAdvice':
        return this.renderPreSaleAdvice()
      case 'question':
        return this.renderQuestion()
      case 'products':
        return this.renderProducts()
      case 'summary':
        return this.renderSummary()
      case 'benefits':
        return this.renderBenefits()
      case 'postSaleAdvice':
        return this.renderPostSaleAdvice()
      case 'related-products':
        return this.renderRelatedProducts()
      default:
        return ''
    }
  }

  render () {
    const { isLoading } = this.state
    if (isLoading) return <Loader />
    return <>{this.renderSteps()}</>
  }
}
const mapStateToProps = state => ({
  currentDepartment: state.app.currentDepartment,
  currentSalesPlan: state.app.currentSalesPlan,
  currentAnswer: state.app.currentAnswer,
  currentProduct: state.app.currentProduct,
  departments: state.app.departments,
  salesPlans: state.app.salesPlans,
  advice: state.app.advice,
  currentQuestion: state.app.currentQuestion,
  questions: state.app.questions,
  answers: state.app.answers,
  products: state.app.products,
  quartileFeatures: state.app.quartileFeatures,
  benefits: state.app.benefits,
  relatedProducts: state.app.relatedProducts,
  navigationUuid: state.analytics.navigationUuid
})
const mapDispatchToProps = {
  getDepartments,
  getSalesPlans,
  getAdvice,
  getBenefits,
  getNextQuestion,
  getPrevQuestion,
  getProducts,
  getQuartileFeatures,
  getRelatedProducts,
  updateCurrentDepartment,
  updateCurrentSalesPlan,
  resetCurrentQuestion,
  addQuestion,
  updateCurrentAnswer,
  resetCurrentAnswer,
  addAnswer,
  updateCurrentProduct,
  resetCurrentProduct,
  resetRelatedProducts,
  resetSteps,
  updateNavigationUuid,
  resetNavigationUuid,
  createAnalytics,
  deleteAnalytics
}
export default connect(mapStateToProps, mapDispatchToProps)(Home)
